export const firebaseConfig = {
  apiKey: "AIzaSyAy6ev4L4hi_EB-MW8Bq21EIXMM4aKtggQ",
  authDomain: "arju-dev.firebaseapp.com",
  projectId: "arju-dev",
  storageBucket: "arju-dev.appspot.com",
  messagingSenderId: "347374461077",
  appId: "1:347374461077:web:8b3983528ce238fc7c7b9c",
  measurementId: "G-204DZYD1PB"
};

export default firebaseConfig;